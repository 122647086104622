import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword, sendOtpCode, verifyOTP} from '../core/_requests'
import {getCountries, getCountryCallingCode} from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
const initialValues = {
  userName: '',
  newPassword: '',
  countryCode: '',
  mobile: '',
  otp: '',
}

const forgotPasswordSchema = (step) =>
  Yup.object().shape({
    ...((step === 0
      ? {
          countryCode: Yup.number().required('Password is required'),
          mobile: Yup.number().required('Mobile Number is required'),
        }
      : step === 1 && {
          mobile: Yup.number().required('Mobile Number is required'),
          otp: Yup.number().required('OTP is required'),
        }) || {
      mobile: Yup.number().required('Mobile No required'),
      newPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    }),
  })

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showStep, setShowStep] = useState(0)
  function getFlagEmoji(country_code: string) {
    const codePoints = country_code
      .toUpperCase()
      .split('')
      .map((char: any) => 127397 + char.charCodeAt())
    return String.fromCodePoint(...codePoints)
  }

  const [countryList, setCountryList] = useState([])

  useEffect(() => {
    const tempData: any = getCountries().map((eachTest) => {
      return {
        label: getFlagEmoji(eachTest) + ` ${en[eachTest]} (+${getCountryCallingCode(eachTest)})`,
        value: getCountryCallingCode(eachTest),
      }
    })
    setCountryList(tempData)
  }, [])
  const makeSelectDropDown = (name = '', options: any = []) => {
    return (
      <select
        // className='form-select form-select-solid form-select-lg'
        className={clsx(
          'form-control bg-transparent',
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        {...formik.getFieldProps(name)}
      >
        <option value=''>Select One...</option>
        {options.map((eachOption, eachInd) => (
          <option key={eachInd + 1 + ''} value={eachOption.value}>
            {eachOption.label}
          </option>
        ))}
      </select>
    )
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema(showStep),
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      try {
        setLoading(true)
        if (showStep === 0) {
          await sendOtpCode(stringifyRequestQuery(values) + '&from=forgotPassword')
          setShowStep(1)
        } else if (showStep === 1) {
          await verifyOTP({mobile: values.mobile, otp: values.otp})
          setShowStep(2)
        } else {
          await resetPassword(values.userName, values.newPassword)
          setShowStep(0)
          setHasErrors(false)
        }
        resetForm()
        setLoading(false)
        setSubmitting(false)
      } catch (error: any) {
        setLoading(false)
        // Handle Axios errors here
        if (error.response) {
          // Server returned an error response (status code not in the 2xx range)
          setStatus(
            error?.response?.data?.errors
              ? {error: error.response?.data?.errors[0]?.message}
              : error.response.data
          ) // Update form errors with server response
        } else if (error.request) {
          // The request was made, but no response was received
          console.error('Request Error:', error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Axios Error:', error.message)
        }
      } finally {
        // setSubmitting(false) // Set submitting to false, whether success or error
      }
    },
  })
  console.log(formik)
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {showStep === 0
            ? 'Enter your mobile number to reset your password.'
            : showStep === 1
            ? 'Enter your mobile number & otp to reset your password'
            : 'Enter your email & password to reset your password'}
        </div>
        {/* end::Link */}
      </div>
      {formik?.status?.error && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status.error}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Password Reset</div>
        </div>
      )}

      {/* begin::Form group */}
      {showStep === 0 && (
        <>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Country</label>
            {makeSelectDropDown('countryCode', countryList)}
            {formik.touched.countryCode && formik.errors.countryCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.countryCode}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Mobile No.</label>
            <input
              type='text'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('mobile')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.mobile && formik.errors.mobile},
                {
                  'is-valid': formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobile}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showStep === 1 && (
        <>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Mobile No.</label>
            <input
              type='mobile'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('mobile')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.mobile && formik.errors.mobile},
                {
                  'is-valid': formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobile}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
            <input
              type='number'
              placeholder=''
              maxLength={8}
              autoComplete='off'
              {...formik.getFieldProps('otp')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.otp && formik.errors.otp},
                {
                  'is-valid': formik.touched.otp && !formik.errors.otp,
                }
              )}
            />
            {formik.touched.otp && formik.errors.otp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.otp}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showStep === 2 && (
        <>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Mobile</label>
            <input
              type='text'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('mobile')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.mobile && formik.errors.mobile},
                {
                  'is-valid': formik.touched.mobile && !formik.errors.mobile,
                }
              )}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobile}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
            <input
              type='password'
              placeholder=''
              maxLength={8}
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {/* end::Form group

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          {!loading && 'Submit'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
