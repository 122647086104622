const UsersListLoading = ({text = 'Processing'}) => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Centers the box
    position: 'fixed', // Fixes the position
    textAlign: 'center',
    zIndex: 9999, // Ensure it stays on top of other content
  }

  return <div style={{ ...styles, position: 'fixed', textAlign: 'center'}}>{text}...</div>
}

export {UsersListLoading}
