/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useRef, useEffect,useState} from 'react'
import {
  generateAddress,
  configCategoryMap,
  toAbsoluteUrl,
} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {ReturnData} from '../../../../../../../_metronic/helpers/components/returnDataHelper'
import ImageLightBox from '../../../../../../../_metronic/helpers/components/ImageLightBox'
import {useListView} from '../../core/ListViewProvider'


import { ViewTableModal } from './ViewTableModal'
import { MapImageModal } from './MapImageModal'



type Props = {
  user: any
  showImage?: boolean
  mapData?: string
  showImageOnly?: boolean
}








const UserInfoCell: FC<Props> = ({
  user,
  showImageOnly = false,
  showImage = false,
  mapData = '',
}) => {
  const lightBoxRef = useRef<any>(null)
  const {itemIdForUpdate} = useListView()

  let imageUrl: any = ''
  if (showImageOnly) {
    let teamImage: any = ''
    if (mapData === 'images') {
      teamImage = user[mapData]?.filter((x) => x?.imageType === 'TEAM_IMAGE')?.[0]
    }
    imageUrl =
      (mapData === 'images' && teamImage?.url) ||
      '' ||
      (mapData === 'kycDocument' && user?.kycDocument?.docUrl) ||
      user?.personalDetails?.profileImage ||
      user?.icon ||
      user.categoryImage ||
      toAbsoluteUrl(`/media/avatars/blank.png`)
  }






  // const [showImageModal, setshowImageModal] = useState({
  //   show: false,
  //   clicked: '',
  // })
  // const [showMapModal, setShowMapModal] = useState({
  //   show: false,
  //   clicked: '',
  // })
  // if (showMapModal.show) {
  //   return (
  //     <MapImageModal
  //       close={() => setShowMapModal({ show: false, clicked: '' })}
  //       data={user}
  //       name={showMapModal.clicked}
  //     />
  //   )
  // }
  // if (showImageModal.show) {
  //   return (
  //     <ViewTableModal
  //       close={() => setshowImageModal({ show: false, clicked: '' })}
  //       data={user}
  //       name={showImageModal.clicked}
  //     />
  //   )
  // }






  return (
    <div className='align-items-center'>
      {showImageOnly && (
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <div className='symbol-label '>
            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
              <img
                src={imageUrl}
                alt={user.name}
                className='w-100'
                onClick={() => lightBoxRef?.current?.open(imageUrl, itemIdForUpdate)}
              />
            </a>
          </div>
        </div>
      )}
      {!showImageOnly && <div className='text-gray-800 mb-1'>{ReturnData(mapData, user)}</div>}
      {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
    </div>
  )
}

// function clipText(text, maxLength = 17, clipper = '...') {
//   if (!text) return text
//   return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
// }

export {UserInfoCell}
