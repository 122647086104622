import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import QRCode from 'qrcode.react';
import DownloadPDFButton from '../../data-administration/data-admininstration-list/table/columns/DownloadPDFButton';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth';

interface OrderDetail {
    orderId: number;
    weight: number;  // Required weight property
}

interface TripDetail {
    tripId: number;
    orderDetails: OrderDetail[];  // Array of orderDetails
}

interface FullfillmentModalProps {
    close: () => void;
    data: {
        id: number;
        name: string;
        tripDetails: TripDetail[] | null;
    } | null;
}

interface ImageType {
    imageType: string;
    latitude: number | null;
    longitude: number | null;
    url: string;
}

const lastSection = [
    { label: 'Company Name', value: '4Ocean PBC' },
    {
        label: 'Regd Address',
        value: `3600 FAU Blvd Boca Raton, Florida USA 33431`,
    },
    { label: 'Email', value: 'tony.ernst@4ocean.com' },
    { label: 'Website', value: 'https://www.4ocean.com' },
]


const FullfillmentModal: React.FC<FullfillmentModalProps> = ({ close, data }) => {

    const { pathname } = useLocation();
    const { currentUser }: any = useAuth();
    const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<any>(null);  // Store the report data
    const [currentOrderId, setCurrentOrderId] = useState<number | null>(null); // Store current order ID

    // Get the token from localStorage
    const getTokenFromLocalStorage = () => {
        const tokenData = localStorage.getItem("kt-auth-react-v");
        if (tokenData) {
            const parsedData = JSON.parse(tokenData);
            return parsedData?.data?.token || null;  // Extract token from parsed data
        }
        return null;
    };

    const openReportModal = async (orderId: number) => {
        const token = getTokenFromLocalStorage();  // Retrieve token from localStorage

        if (!token) {
            console.error('No token found');
            return;
        }

        setIsLoading(true);  // Set loading to true while waiting for the response
        setIsReportModalOpen(true);  // Open the report modal
        setCurrentOrderId(orderId);   // Store the current order ID

        try {
            const response = await fetch(`https://4ocean-backend-api.asmglobal.co.in/v1/api/orders/${orderId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Adding token to Authorization header
                    'Content-Type': 'application/json',  // Ensure correct content type
                },
            });

            const data = await response.json();
            console.log("Data received from the API:", data);

            // Check if data contains 'data' array
            if (data && Array.isArray(data.data)) {
                // Find the order object within the 'data' array
                const matchedOrder = data.data.find((item: any) => item.id === orderId);

                if (matchedOrder) {
                    setReportData(matchedOrder);  // Set the matched order as report data
                    console.log("Matched Order:", matchedOrder);
                } else {
                    console.log("No matching order found for orderId:", orderId);
                    setReportData(null);  // If no match found, set reportData to null
                }
            } else {
                console.error('Data does not contain a valid "data" array:', data);
                setReportData(null);  // If 'data' is missing or not an array, set reportData to null
            }

        } catch (error) {
            console.error('Error fetching report:', error);
            setReportData(null);  // If an error occurs, set reportData to null
        } finally {
            setIsLoading(false);  // Stop loading once the request is complete
        }
    };

    const closeReportModal = () => {
        setIsReportModalOpen(false);  // Close the report modal
        setReportData(null);  // Clear the report data
    };

    if (!data || !data.tripDetails || data.tripDetails.length === 0) {
        return (
            <div style={modalStyles.overlay}>
                <div style={modalStyles.modal}>
                    <div style={modalStyles.header}>
                        <h3>Fulfillment Details</h3>
                        <button onClick={close} style={modalStyles.closeButton}>X</button>
                    </div>
                    <div style={modalStyles.body}>
                        <p>No records found.</p>
                    </div>
                    <div style={modalStyles.footer}>
                        <button onClick={close} style={modalStyles.closeButton}>Close</button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
                <div style={modalStyles.header}>
                    <h3>Fulfillment Details</h3>
                    <button onClick={close} style={modalStyles.closeButton}>X</button>
                </div>
                <div style={modalStyles.body}>
                    <table style={modalStyles.table}>
                        <thead>
                            <tr>
                                <th style={modalStyles.th}>SL</th>
                                <th style={modalStyles.th}>Impact ID</th>
                                <th style={modalStyles.th}>Order ID</th>
                                <th style={modalStyles.th}>View Report</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.tripDetails.map((trip, index) => (
                                <tr key={index}>
                                    <td style={modalStyles.td}>{index + 1}</td>
                                    <td style={modalStyles.td}>{trip.tripId}</td>
                                    <td style={modalStyles.td}>
                                        {trip.orderDetails.length > 0 ? trip.orderDetails[0].orderId : "N/A"}
                                    </td>
                                    <td style={modalStyles.td}>
                                        <a
                                            href="#"
                                            style={modalStyles.link}
                                            onClick={() => openReportModal(trip.orderDetails[0].orderId)}>
                                            Click to view
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={modalStyles.footer}>
                    <button onClick={close} style={modalStyles.closeButton}>Close</button>
                </div>
            </div>

            {/* Report Modal */}
            {isReportModalOpen && (
                <div style={modalStyles.overlay}>
                    <div style={modalStyles.modal}>
                        <div style={modalStyles.header}>
                            <h3></h3>
                            <button onClick={closeReportModal} style={modalStyles.closeButton}>X</button>
                        </div>
                        <div style={modalStyles.body}>
                            {isLoading ? (
                                <p>Loading report...</p>
                            ) : reportData ? (
                                <div>
                                    {/* Add your custom report display code here */}
                                    <div className='col-13' style={{ marginTop: '15px', display: "flex", justifyContent: "end" }}>
                                        <img
                                            width={'25%'}
                                            height={'50px'}
                                            style={{ backgroundColor: '#FFFFFF', marginLeft: '9px', fontFamily: 'GT Walsheim Pro', }}
                                            src={toAbsoluteUrl('/media/logos/real.jpeg')}
                                            alt='main-logo'
                                        />
                                    </div>

                                    <div
                                        style={{
                                            color: '#0057B8',
                                            fontSize: '40px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Montserrat, sans-serif',
                                            lineHeight: '54px',
                                            textAlign: 'center',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundImage: 'url(/media/cleanUpImages/bg.PNG)',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            padding: '35px',
                                            height: "300px"
                                        }}
                                    >
                                        <span
                                            style={{
                                                position: "relative", // Apply relative positioning to the text
                                                top: "-30px" // Move text higher, adjust as needed
                                            }}
                                        >
                                            Clean-up Report
                                        </span>
                                    </div>


                                    <div style={{ display: "flex" }}>
                                        <div className='col-9'>
                                            <div className='row'>
                                                <div className='col-11'>

                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px', width: '100%', fontFamily: 'Montserrat, sans-serif', }}>
                                                        <img
                                                            src="/media/cleanUpImages/weight.PNG"
                                                            alt="Location"
                                                            style={{ height: '50%' }}
                                                        />
                                                        <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                                                            <p
                                                                style={{ fontSize: "30px", margin: "0", color: "#0457b8", fontWeight: "bold" }}
                                                            >

                                                                {
                                                                    reportData?.orderDetails?.map(order =>
                                                                        // Sum up the weight of each order item (order.weight)
                                                                        order?.weight || 0
                                                                    ).reduce((totalWeight, orderWeight) => totalWeight + orderWeight, 0)
                                                                }




                                                            </p>

                                                            <span style={{ fontSize: "30px", color: "#0457b8", fontWeight: "bold" }}>Pounds pulled</span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-3 d-flex justify-content-center align-items-center">
                                            {reportData && (
                                                <QRCode
                                                    key={reportData.id} // Use a unique key for the QR code (e.g., reportData.id)
                                                    value={`${process.env.REACT_APP_BASE_API_URL}v1/certificate/download?orderId=${reportData.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'}`}
                                                />
                                            )}
                                        </div>







                                    </div>



                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0', width: '100%', fontFamily: 'Montserrat, sans-serif', }}>
                                        {/* Date Section */}
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px', width: '30%' }}>
                                            <img
                                                src="/media/cleanUpImages/date.PNG"
                                                alt="Date"
                                                style={{ width: '70px', height: 'auto' }}
                                            />
                                            <div style={{ fontSize: '20px', margin: '0 0 0 5px', color: '#0457b8', fontWeight: 'bold' }}>

                                                <div >
                                                    {new Date().toLocaleDateString() || ''}
                                                </div>

                                            </div>
                                        </div>
                                        {/* Location Section */}
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px', width: '30%' }}>
                                            <img
                                                src="/media/cleanUpImages/location.PNG"
                                                alt="Location"
                                                style={{ width: '70px', height: 'auto' }}
                                            />
                                            <div style={{ fontSize: '20px', margin: '0 0 0 5px', color: '#0457b8', fontWeight: 'bold' }}>

                                                <div>
                                                    {reportData?.dispatchInfo?.street || 'No street information'}
                                                </div>

                                            </div>
                                        </div>


                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '8px', width: '30%' }}>
                                            <img
                                                src="/media/cleanUpImages/river.PNG"
                                                alt="River"
                                                style={{ width: '70px', height: 'auto' }}
                                            />
                                            <div style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>


                                                <div >
                                                    {(reportData?.dispatchInfo?.latitute
                                                        ? reportData?.dispatchInfo?.latitute.toFixed(6).slice(0, 12) // Limits to 6 decimals, 12 characters
                                                        : '') +
                                                        ', ' +
                                                        (reportData?.dispatchInfo?.longitute
                                                            ? reportData?.dispatchInfo?.longitute.toFixed(6).slice(0, 12) // Limits to 6 decimals, 12 characters
                                                            : '')}
                                                </div>

                                            </div>
                                        </div>

                                    </div>




                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                                        {/* Wrapper div for side-by-side content */}
                                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%', alignItems: 'center' }}>

                                            {/* Mapping over user?.data */}

                                            <div style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>

                                                {/* Crew Employed Section */}
                                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                                                    <img
                                                        src="/media/cleanUpImages/people.PNG"
                                                        alt="Crew Employed"
                                                        style={{ height: '50px', width: 'auto' }} // Set a fixed height for the image
                                                    />
                                                    <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                                                        <p style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>
                                                            {reportData?.teamInfo?.size ?? '-'} {/* Handling null or undefined */}
                                                        </p>
                                                        <span style={{ fontSize: '20px', color: '#0457b8', fontWeight: 'bold' }}>Crew Employed</span>
                                                    </div>
                                                </div>

                                                {/* Captain Section */}
                                                <div style={{ display: 'flex', alignItems: 'center', padding: '8px', flex: 1, justifyContent: 'center', fontFamily: 'Montserrat, sans-serif' }}>
                                                    <img
                                                        src="/media/cleanUpImages/lo.PNG"
                                                        alt="Captain"
                                                        style={{ height: '50px', width: 'auto' }} // Set a fixed height for the image
                                                    />
                                                    <div style={{ marginLeft: '10px', textAlign: 'left' }}>
                                                        <p style={{ fontSize: '20px', margin: '0', color: '#0457b8', fontWeight: 'bold' }}>
                                                            {reportData?.customerInfo?.name ?? 'N/A'} {/* Fallback to 'N/A' if name is unavailable */}
                                                        </p>
                                                        <span style={{ fontSize: '20px', color: '#0457b8', fontWeight: 'bold' }}>Captain</span>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: "50px"
                                    }}>

                                        {/* Check if reportData has images and display them */}
                                        {reportData?.images?.length > 0 && (() => {
                                            // Group images by their type just once
                                            const groupedImages: Record<string, ImageType[]> = {};
                                            reportData.images.forEach((image: ImageType) => {
                                                if (!groupedImages[image.imageType]) {
                                                    groupedImages[image.imageType] = [];
                                                }
                                                groupedImages[image.imageType].push(image);
                                            });

                                            // Map imageType to custom labels
                                            const imageTypeLabels: Record<string, string> = {
                                                'PRE_STAGE': 'Before Clean Up',
                                                'IN_PROGRESS_STAGE': 'During Clean Up',
                                                'POST_STAGE': 'After Clean Up',
                                                'TNC_SIGNATURE': 'Signature',
                                                'LOADING': 'Loading',
                                                'SCALE_PIC': 'Scale Pic',
                                                'SLIP': 'Slip',
                                                'TEAM_IMAGE': reportData.teamInfo?.teamName || 'Team Name'
                                            };

                                            return Object.entries(groupedImages).map(([imageType, images]: [string, ImageType[]]) => (
                                                <div key={imageType} style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flexStart',
                                                    width: '700px',  // Width of two 280px items
                                                    marginBottom: '20px',
                                                }}>
                                                    {/* Render the label once per group */}
                                                    <div style={{
                                                        display: 'flex',
                                                        marginBottom: '10px'
                                                    }}>
                                                        <div style={{
                                                            width: '300px',
                                                            height: '300px',
                                                            backgroundColor: 'rgb(4, 85, 185)',
                                                            color: "white",
                                                            fontWeight: "bold",
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            // borderRadius: '4px',
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            fontSize: '18px',
                                                            textAlign: 'center',
                                                            fontFamily: "'Montserrat', sans-serif"
                                                        }}>
                                                            {imageTypeLabels[imageType] || imageType}
                                                        </div>
                                                        {images[0] && (
                                                            <div style={{
                                                                width: '300px',
                                                                height: '300px',
                                                                backgroundImage: `url(${images[0].url})`,
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',
                                                                // borderRadius: '4px',
                                                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                                                            }} />
                                                        )}
                                                    </div>

                                                    {/* Rendering the remaining images in pairs */}
                                                    {images.slice(1).reduce((rows: any[], image: ImageType, index: number) => {
                                                        if (index % 2 === 0) {
                                                            rows.push(
                                                                <div key={index} style={{
                                                                    display: 'flex',
                                                                    marginBottom: '0'
                                                                }}>
                                                                    <div style={{
                                                                        width: '300px',
                                                                        height: '300px',
                                                                        backgroundImage: `url(${image.url})`,
                                                                        backgroundSize: 'cover',
                                                                        backgroundPosition: 'center',
                                                                        // borderRadius: '4px',
                                                                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                                                                        // marginRight: '10px'
                                                                    }} />
                                                                    {images[index + 1] && (
                                                                        <div style={{
                                                                            width: '300px',
                                                                            height: '300px',
                                                                            backgroundImage: `url(${images[index + 1].url})`,
                                                                            backgroundSize: 'cover',
                                                                            backgroundPosition: 'center',
                                                                            // borderRadius: '4px',
                                                                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                                                                        }} />
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                        return rows;
                                                    }, [])}
                                                </div>
                                            ));
                                        })()}
                                    </div>



                                    <div
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '-7px',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            fontFamily: 'IBM Plex Mono, monospace'
                                        }}
                                    >
                                        Pulled Material Details
                                    </div>



                                    <div>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                margin: '20px 0',
                                                fontFamily: 'IBM Plex Mono, monospace'
                                            }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        SL
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        Category
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        Item
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        Weight
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        UOM
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        Quantity
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            padding: '8px',
                                                            backgroundColor: '#f2f2f2',
                                                            textAlign: 'left',
                                                            fontFamily: 'IBM Plex Mono, monospace'
                                                        }}
                                                    >
                                                        UOM
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData?.orderDetails?.map((detail, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {index + 1} {/* Combined SL */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.categoryName}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.itemName}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.weight}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.wightUnit}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.quantity}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                padding: '8px',
                                                                fontFamily: 'IBM Plex Mono, monospace',
                                                            }}
                                                        >
                                                            {detail.unit}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>




                                    <div
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '7px',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            fontFamily: 'IBM Plex Mono, monospace'
                                        }}
                                    >
                                        Processing Facility
                                    </div>






                                    <div>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                margin: '20px 0',
                                                fontFamily: 'IBM Plex Mono, monospace',
                                            }}
                                        >
                                            <tbody>

                                                <tr >
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Name:
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {reportData?.hubInfo?.name || 'N/A'}
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Street:
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {reportData?.hubInfo?.address?.street || 'N/A'}
                                                    </td>
                                                </tr>


                                                <tr >
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        City:
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {reportData?.hubInfo?.address?.city || 'N/A'}
                                                    </td>
                                                </tr>


                                                <tr >
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Country:
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {reportData?.hubInfo?.address?.country || 'N/A'}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Zip Code:
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '4px', // Reduced padding for less row height
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {reportData?.hubInfo?.address?.zipCode || 'N/A'}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-11' style={{ background: "#f8f8f4" }}>
                                                    {lastSection?.map((eachData, ind) => (
                                                        <div
                                                            className='row'
                                                            key={ind + 1 + ''}
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'IBM Plex Mono, monospace'
                                                            }}
                                                        >
                                                            <label className='col-lg-3' style={{ color: '#242727', fontWeight: '700' }}>
                                                                {eachData.label}
                                                            </label>
                                                            <div className='col-lg-8' style={{ color: '#4F5152' }}>
                                                                <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <div style={{ fontFamily: 'IBM Plex Mono, monospace', fontSize: "10px", margin: "10px 0" }}>
                                            This is not a tax invoice. This is computer generated document, no signature is
                                            required
                                        </div>
                                        <div></div>
                                    </div>

                                    <div className='col-13' style={{ display: "flex", justifyContent: "end" }}>
                                        <DownloadPDFButton
                                            url={`${process.env.REACT_APP_BASE_API_URL}v1/certificate/download?orderId=${reportData.id}&type=${pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'}`}
                                        />
                                    </div>




                                </div>
                            ) : (
                                <p>No data available for this report.</p>
                            )}
                        </div>
                        <div style={modalStyles.footer}>
                            <button onClick={closeReportModal} style={modalStyles.closeButton}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const modalStyles: Record<string, React.CSSProperties> = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '850px',
        maxHeight: '95vh',  // Allow the modal to take up almost full height but keep some margin at the top/bottom
        height: 'auto',  // Let the height be auto to fit content initially
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto',  // Allow vertical scrolling if content overflows
        display: 'flex',
        flexDirection: 'column',  // Make sure the modal content is stacked vertically
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        paddingBottom: '10px',
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    closeButton: {
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '15px',
        cursor: 'pointer',
    },
    body: {
        flexGrow: 1,  // Allow body to take up remaining space and push footer down
        maxHeight: 'calc(100vh - 200px)',  // Allow for dynamic height of the body area but limit it
        overflowY: 'auto',  // Enable vertical scrolling for the body
        paddingRight: '15px',  // Add padding to prevent content from touching the scrollbar
    },
    footer: {
        textAlign: 'right',
        paddingTop: '10px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        padding: '10px',
        textAlign: 'center',
        // backgroundColor: '#f2f2f2',
        fontWeight: "bold"
    },
    td: {
        padding: '10px',
        textAlign: 'center',
    },
    link: {
        color: '#007BFF',
        textDecoration: 'none',
    },
};



export default FullfillmentModal;
