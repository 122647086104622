/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  // Open modal
  const openModal = () => {
    console.log("Opening modal"); // Add log to check
    setIsModalOpen(true);
  }


  // Close modal
  const closeModal = () => setIsModalOpen(false)


  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])



  const PrivacyPolicyModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Semi-transparent background
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1050, // Ensure the modal appears above other content
        }}
        onClick={onClose}  // Close modal when clicking outside
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            width: '80%',
            maxWidth: '800px',
            zIndex: 1060,  // Modal content above overlay
            maxHeight: '80vh',  // Set max height to 80% of the viewport height
            overflowY: 'auto',  // Allow scrolling if content exceeds max height
            position: 'relative',  // To contain the inner elements
          }}
          onClick={(e) => e.stopPropagation()}  // Prevent modal from closing when clicked inside
        >
          <h2 style={{ textAlign: "center" }}>Privacy Policy</h2>
          <p>
            <strong>   Privacy Policy  Introduction:</strong>


            <br />

            Consulere ASM Global LLP (hereinafter “ASM”) recognizes the importance of privacy of its users and also of maintaining confidentiality of the information provided by its users as a responsible data controller and data processer. <br />

            This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by ASM and its subsidiaries and affiliates.  <br />

            This Privacy Policy is applicable to any person (‘User’) who uses any of the ASM applications for business or personal usage.  <br />

            For the purpose of this Privacy Policy, wherever the context so requires "you" or "your" shall mean User and the term "we", "us", "our" shall mean ASM. For the purpose of this Privacy Policy, Website means the website(s), mobile site(s) and mobile app(s).  <br />

            By using or accessing the Website or other Sales Channels, the User hereby agrees with the terms of this Privacy Policy and the contents herein. If you disagree with this Privacy Policy please do not use or access our Website or other Sales Channels.  <br />

            This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website. User should take note that information and privacy practices of ASM’s business partners, advertisers, sponsors or other sites to which ASM provides hyperlink(s), may be materially different from this Privacy Policy. Accordingly, it is recommended that you review the privacy statements and policies of any such third parties with whom they interact.  <br />

            This Privacy Policy is an integral part of your User Agreement with ASM and all capitalized terms used, but not otherwise defined herein, shall have the respective meanings as ascribed to them in the User Agreement.  <br />



            USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF INDIA  <br />

            Please note that the data shared with ASM shall be primarily processed in India and such other jurisdictions where a third party engaged by ASM may process the data on ASM’s behalf. By agreeing to this policy, you are providing ASM with your explicit consent to process your personal information for the purpose(s) defined in this policy. The data protection regulations in India or such other jurisdictions mentioned above may differ from those of your country of residence.  <br />

            If you have any concerns in the processing your data and wish to withdraw your consent, you may do so by writing to the following email id: info@asmglobal.co.in However, if such processing of data is essential for us to be able to provide service to you, then we may not be able to serve after your withdrawal of consent.  <br />



            TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS <br />

            The information as detailed below is collected for us to be able to provide the services chosen by you and also to fulfill our legal obligations as well as our obligations towards third parties as per our User Agreement.  <br />

            "Personal Information" of User shall include the information shared by the User and collected by us for the following purposes:  <br />

            Registration on the Website or Apps: Information which you provide while subscribing to or registering on the Website, including but not limited to information about your personal identity such as name, gender, marital status, religion, age, profile picture etc., your contact details such as your email address, postal addresses, telephone (mobile or otherwise) and/or fax numbers. The information may also include information such as your banking details (including credit/debit card) and any other information relating to your income and/or lifestyle; billing information payment history etc. (as shared by you).  <br />

            The images obtained from government identification document and photos of your face that you upload, are collected, used and stored directly by ASM on behalf of Customer as Customer’s service provider through Customer’s website or app that you accessed. Depending on our relationship with the Customer, the Customer may upload your government identification document and photos of your face directly to us.  <br />

            ASM securely stores all photos of identity documents that you upload, photos of your face that you upload in an encrypted format. ASM’s third-party vendors may have access to the data from scans of facial geometry extracted from the photos of your face that you upload to provide some or all of the analysis, to store the data, to maintain backup copies, and to service the systems on which such data is stored. ASM will permanently destroy data from scans of facial geometry extracted from the photos of your face that you upload upon completion of Verification or within ten years of your last interaction with ASM, consistent with the Customer’s instructions unless ASM is otherwise required by law or legal process to retain the data. <br />

            ASM uses the reasonable standards of care within its industry to store, transmit, and protect from disclosure data from scans of facial geometry extracted from the photos of your face that you upload in a manner that is the same as or more protective than the manner in which it stores, transmits, and protects other confidential and sensitive information. ASM will not sell, lease, trade, or, other than to provide the Verification and Fraud Prevention services to Customer described in this policy, otherwise benefit from data from scans of facial geometry extracted from the photos of your face that you upload. Other than as set forth herein, Persona will not disclose, redisclose, or otherwise disseminate data from scans of facial geometry extracted from the photos of your face. <br />

            Other information: We many also collect some other information and documents including but not limited to: <br />

            · Transactional history (other than banking details) about your e-commerce activities, buying behavior.  <br />

            · Your usernames, passwords, email addresses and other security-related information used by you in relation to our Services.  <br />

            · Data either created by you or by a third party and which you wish to store on our servers such as image files, documents etc.  <br />

            · Data available in public domain or received from any third party including social media channels, including but not limited to personal or non-personal information from your linked social media channels (like name, email address, friend list, profile pictures or any other information that is permitted to be received as per your account settings) as a part of your account information.  <br />

            Such information shall be strictly used for the aforesaid specified & lawful purpose only. User further understands that ASM may share this information with the end service provider or any other third party for provision and facilitation of the transaction. ASM will always redact all/any sensitive & confidential information contained in the vaccination certificate, passbook, bank statement or any other identity card submitted for the purpose of availing a service. In case a User does not wish to provide this information or opts for deletion of the information already provided, ASM may not be able to process the desired services. ASM will never share any of the above information collected without their prior consent unless otherwise such action is required by any law enforcement authority for investigation, by court order or in reference to any legal process.  <br />



            HOW WE USE YOUR PERSONAL INFORMATION:  <br />

            The Personal Information collected maybe used in the following manner:  <br />

            While making a transaction  <br />

            While making a transaction, we may use Personal Information including, location, mobile number, address as shared and allowed to be stored by you. This information is presented to the User at the time of making a transaction to enable you to complete your bookings expeditiously.  <br />

            We may also use your Personal Information for several reasons including but not limited to:  <br />

            · Create login credentials  <br />

            · Confirm pick up order generation  <br />

            · Generate return requests  <br />

            · Accept reward points  <br />

            · Redeem reward points  <br />

            Surveys:  <br />

            We value opinions and comments from our Users and frequently conduct surveys, both online and offline. Participation in these surveys is entirely optional. Typically, the information received is aggregated, and used to make improvements to Website, other Sales Channels, services and to develop appealing content, features and promotions for members based on the results of the surveys. Identity of the survey participants is anonymous unless otherwise stated in the survey.  <br />

            Marketing Promotions, Research and Programs:  <br />

            Marketing promotions, research and programs help us to identify your preferences, develop programs and improve user experience. ASM frequently sponsors promotions to give its Users the opportunity to earn greater reward points. Personal Information collected by us for such activities may include contact information and survey questions. We use such Personal Information to notify contest winners and survey information to develop promotions and product improvements. In addition, you may look forward to receiving periodic marketing emails, newsletters and exclusive promotions offering special deals.  <br />



            HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?  <br />

            ASM will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements.  <br />

            Where your personal data is no longer required we will ensure it is either securely deleted or stored in a way which means it will no longer be used by the business.  <br />



            COOKIES AND SESSION DATA (APPLICABLE FOR WEBSITE USERS ONLY)  <br />

            Cookies:  <br />

            ASM uses cookies to personalize your experience on the Website and the advertisements that maybe displayed. ASM’s use of cookies is similar to that of any other reputable online companies.  <br />

            Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more efficiently. Cookies also allow ease of access, by logging you in without having to type your login name each time (only your password is needed); we may also use such cookies to display any advertisement(s) to you while you are on the Website or to send you offers (or similar emails – provided you have not opted out of receiving such emails) focusing on destinations which may be of your interest.  <br />

            A cookie may also be placed by our advertising servers, or third party advertising companies. Such cookies are used for purposes of tracking the effectiveness of advertising served by us on any website, and also to use aggregated statistics about your visits to the Website in order to provide advertisements in the Website or any other website about services that may be of potential interest to you. The third party advertising companies or advertisement providers may also employ technology that is used to measure the effectiveness of the advertisements. All such information is anonymous. This anonymous information is collected through the use of a pixel tag, which is an industry standard technology and is used by all major websites. They may use this anonymous information about your visits to the Website in order to provide advertisements about goods and services of potential interest to you. No Personal Information is collected during this process. The information so collected during this process, is anonymous, and does not link online actions to a User  <br />

            Most web browsers automatically accept cookies. Of course, by changing the options on your web browser or using certain software programs, you can control how and whether cookies will be accepted by your browser. ASM supports your right to block any unwanted Internet activity, especially that of unscrupulous websites. However, blocking ASM cookies may disable certain features on the Website, and may hinder an otherwise seamless experience to purchase or use certain services available on the Website. Please note that it is possible to block cookie activity from certain websites while permitting cookies from websites you trust.  <br />

            Automatic Logging of Session Data:  <br />

            Each time you access the Website your session data gets logged. Session data may consist of various aspects like the IP address, operating system and type of browser software being used and the activities conducted by the User while on the Website. We collect session data because it helps us analyze User’s choices, browsing pattern including the frequency of visits and duration for which a User is logged on. It also helps us diagnose problems with our servers and lets us better administer our systems. The aforesaid information cannot identify any User personally. However, it may be possible to determine a User's Internet Service Provider (ISP), and the approximate geographic location of User's point of connectivity through the above session data.  <br />



            WITH WHOM YOUR PERSONAL INFORMATION IS SHARED  <br />

            Service Providers and suppliers:  <br />

            Your information shall be shared with the end service providers like collection centres, pick up vehicles, recyclers who provide the relevant services. You may note that while making a booking with ASM you authorize us to share your information with the said service providers and suppliers. It is pertinent to note that ASM does not authorize the end service provider to use your information for any other purpose(s) except as may be for fulfilling their part of service. However, how the said service providers/suppliers use the information shared with them is beyond the purview and control of ASM as they process Personal Information as independent data controllers, and hence we cannot be made accountable for the same. You are therefore advised to review the privacy policies of the respective service provider or supplier whose services you choose to avail.  <br />

            ASM does not sell or rent individual customer names or other Personal Information of Users to third parties except sharing of such information with our business / alliance partners or vendors who are engaged by us for providing various referral services and for sharing promotional and other benefits to our customers from time to time basis their booking history with us.

            COMPANIES IN THE SAME GROUP:  <br />

            In the interests of improving personalization and service efficiency, we may, under controlled and secure circumstances, share your Personal Information with our affiliate or associate entities. If the assets of ASM are acquired, our customer information may also be transferred to the acquirer depending upon the nature of such acquisition. In addition, as part of business expansion/development/restructuring or for any other reason whatsoever, if we decide to sell/transfer/assign our business, any part thereof, any of our subsidiaries or any business units, then as part of such restructuring exercise customer information including the Personal Information collected herein shall be transferred accordingly.  <br />

            BUSINESS PARTNERS and THIRD-PARTY VENDORS:  <br />

            We may also share certain filtered Personal Information to our corporate affiliates or business partners who may contact the customers to offer certain products or services, which may include free or paid products / services, which will enable the customer to have better user experience or to avail certain benefits specially made for ASM customers. Examples of such partners are entities offering fuel points, coupons, airtime etc. If you choose to avail any such services offered by our business partners, the services so availed will be governed by the privacy policy of the respective service provider.   <br />

            ASM may share your Personal Information to third party that ASM may engage to perform certain tasks on its behalf, including but not limited to payment processing, data hosting, and data processing platforms.  <br />

            We use non-identifiable Personal Information of Users in aggregate or anonymized form to build higher quality, more useful online services by performing statistical analysis of the collective characteristics and behavior of our customers and visitors, and by measuring demographics and interests regarding specific areas of the Website. We may provide anonymous statistical information based on this data to suppliers, advertisers, affiliates and other current and potential business partners.  <br />

            Occasionally, ASM will hire a third party for market research, surveys etc. and will provide information to these third parties specifically for use in connection with these projects. The information (including aggregate cookie and tracking information) we provide to such third parties, alliance partners, or vendors are protected by confidentiality agreements and such information is to be used solely for completing the specific project, and in compliance with the applicable regulations.  <br />

            DISCLOSURE OF INFORMATION  <br /> <br />

            In addition to the circumstances described above, ASM may disclose User's Personal Information if required to do so:  <br />

            · by law, required by any enforcement authority for investigation, by court order or in reference to any legal process;  <br />

            · to conduct our business;  <br />

            · for regulatory, internal compliance and audit exercise(s)  <br />

            · to secure our systems; or  <br />

            · to enforce or protect our rights or properties of ASM or any or all of its affiliates, associates, employees, directors or officers or when we have reason to believe that disclosing Personal Information of User(s) is necessary to identify, contact or bring legal action against someone who may be causing interference with our rights or properties, whether intentionally or otherwise, or when anyone else could be harmed by such activities.  <br />

            Such disclosure and storage may take place without your knowledge. In that case, we shall not be liable to you or any third party for any damages howsoever arising from such disclosure and storage.  <br /> <br />



            HOW CAN YOU OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS?  <br /> <br />

            You will occasionally receive e-mail updates from us about fare sales in your area, special offers, new ASM services, and other noteworthy items. We hope you will find these updates interesting and informative. If you wish not to receive them, please click on the "unsubscribe" link or follow the instructions in each e-mail message.  <br />



            PERMISSIONS REQUIRED FOR USING OUR MOBILE APPLICATIONS  <br />

            When the ASM app is installed on your phone or tablet, a list of permissions appear and are needed for the app to function effectively. There is no option to customize the list. The permissions that ASM requires and the data that shall be accessed and its use is as below: <br />

            Apple & Android permissions:  <br />

            Device & App history: We need your device permission to get information about your device, like OS (operating system) name, OS version, mobile network, hardware model, unique device identifier, preferred language, etc. Basis these inputs, we intend to optimize your experience, use OS specific capabilities to drive great in-funnel experiences using components of device’s OS, etc. <br />

            Camera: This permission enables you to use your camera from within the application to upload a new profile picture, upload an image for sharing material information our application. This permission will also enable you to scan QR codes for material exchange, validate material transactions and chain of custody. <br />

            Identity: This permission enables us to know about details of your account(s) on your mobile device. We use this info to auto-fill your email ID’s and provide a typing free in-funnel experience. It helps us map email ID’s to a particular user to give you the benefit of exclusive offers, etc. It also allows facilitating your Facebook and Google+ login. <br />

            Location: This permission enables us to give you the benefit of location specific deals and provide you a personalized in-funnel experience. When you launch the app to make a transaction, we auto-detect your location so that your nearest collection centre or facility is auto-filled. <br />

            SMS: If you allow us to access your SMS, we read your SMS to autofill or prepopulate ‘OTP’ while making a transaction and to validate your mobile number. This provides you a seamless purchase experience while making a booking and you don’t need to move out of the app to read the SMS and then enter it in the app. <br />

            Phone: The app requires access to make phone calls so that you can make phone calls to collection centres directly through the app. <br />

            Photo/ Media/ Files: The libraries in the app use these permissions to allow map data to be saved to your phone's external storage, like SD cards. By saving map data locally, your phone doesn't need to re-download the same map data every time you use the app. This provides you a seamless Map based drop off points selection experience, even on low bandwidth network. <br />

            Wi-Fi connection information: When you allow us the permission to detect your Wi-Fi connection, we optimize your experience  <br />

            Bluetooth: This permission enables us to provide you services of the third parties that make available certain features of their products/services on our application. The permission specifically enables our third party service providers whose services you book on the application to permit you to lock and unlock your self-driven vehicles seamlessly.  <br />



            WITHDRAWAL OF CONSENT AND PERMISSION  <br />

            You may withdraw your consent to submit any or all Personal Information or decline to provide any permissions on its Website as covered above at any time. In case, you choose to do so then your access to the Website may be limited, or we might not be able to provide the services to you. You may withdraw your consent by sending an email to info@asmglobal.co.in  <br />



            ACCOUNT DELETION  <br />

            Upon deletion of account from any of our app using the in-app function of account deletion, the user information collected and stored will be deleted from the database immediately. The user shall no longer be able to access the app.  <br />



            ELIGIBILITY TO TRANSACT WITH ASM <br />

            You must at least 18 years of age to transact directly with ASM and also to consent to the processing of your personal data.  <br />



            CHANGES TO THE PRIVACY POLICY  <br />

            We reserve the rights to revise the Privacy Policy from time to time to suit various legal, business and customer requirement. We will duly notify the users as may be necessary.  <br />

            You may always submit concerns regarding this Privacy Policy via email to us at info@asmglobal.co.in . ASM shall endeavour to respond to all reasonable concerns and inquires.</p>
          <button
            style={{
              backgroundColor: '#0057B8',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={onClose}  // Close modal when button clicked
          >
            Close
          </button>
        </div>
      </div>
    )
  }


  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-2 order-2 order-lg-1  z-10'
        style={{ background: '#0057B8' }}
      >
        {/* begin::Form */}
        <div className='d-flex'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-60px' />
        </div>
        <div className='d-flex flex-center flex-column p-20 flex-lg-row-fluid text-white'>
          <div style={{ fontSize: '42px', marginBottom: '42px' }}>Good to see you again</div>{' '}
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold fs-base'>
            <a href='#' className='px-5 text-white' target='_blank' rel='noopener noreferrer'>
              Copyright &copy; {new Date().getFullYear().toString()} ASM Global
            </a>
            <a
              href='#'
              className='px-5 text-white'
              onClick={openModal}  // Open modal when clicked
            >
              Privacy policy
            </a>

            {/* <a href='#' className='px-5' target='_blank'>
              Plans
            </a> */}

            <a href="https://asm-recyclx.com/contact.html" className='px-5 text-white' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover order-1 order-lg-2 overflow-hidden position-relative'>
        <div
          // className='position-fixed'
          style={{ left: '50%' }}
        >
          <video autoPlay muted loop style={{ maxWidth: '100%', position: 'fixed', left: '27%' }}>
            <source src='/media/logos/intro_video.mp4' type='video/mp4' />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
      {/* end::Aside */}
      <PrivacyPolicyModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  )
}

export { AuthLayout }
