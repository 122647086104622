/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTIcon } from '../../../../helpers'

type Props = {
  className: string
}

// Update the type to include the `url` property
const rows: Array<{ description: string; url: string }> = [
  { description: 'Website', url: 'https://www.4ocean.com/' },
  { description: 'LinkedIn', url: 'https://www.linkedin.com/company/4oceanpbc/' },
  { description: 'Instagram', url: 'https://www.instagram.com/4ocean/?hl=en' },
]

const ListsWidget26 = ({ className }: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>External Links</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <a
              href={row.url}  // Dynamic URL binding here
              className='text-primary fw-semibold fs-6 me-2'
              target='_blank'  // Optionally, open link in a new tab
              rel='noopener noreferrer'  // Security when opening in a new tab
            >
              {row.description}
            </a>
            <button
              type='button'
              className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
            >
              <KTIcon iconName='exit-right-corner' className='fs-2' />
            </button>
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)

export { ListsWidget26 }
