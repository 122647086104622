import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import ReactPaginate from 'react-paginate'

interface UsersListPaginationProps {
  initialApi?: any
  onPageChange?: (page: number) => void
}

const UsersListPagination: React.FC<UsersListPaginationProps> = ({ initialApi, onPageChange }) => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState, state } = useQueryRequest()

  // State Tracking
  const [loadingState, setLoadingState] = useState<boolean>(false)

  // Dynamic Page Size Options
  const options = useMemo(() => {
    const baseOptions = [10, 20, 50, 100, 200, 300, 500]
    return baseOptions.filter((option) => option <= (pagination.totalItems || 1000))
  }, [pagination.totalItems])

  // Pagination Handler
  const handlePagination = useCallback(
    (selectedItem: { selected: number }) => {
      // Force loading state
      setLoadingState(true)

      // Call optional external page change handler with 1-based index
      onPageChange?.(selectedItem.selected)

      updateState({
        page: selectedItem.selected,
        ...((initialApi && { initialApi }) || {}),
      })
    },
    [updateState, initialApi, onPageChange]
  )

  // Page Size Change Handler
  const handlePerPage = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const perPage = parseInt(event.target.value, 10)

      // Force loading state
      setLoadingState(true)

      updateState({
        size: perPage,
        page: 0, // Reset to first page
        ...((initialApi && { initialApi }) || {}),
      })
    },
    [updateState, initialApi]
  )

  // Reset Loading State
  useEffect(() => {
    const shouldResetLoading = !isLoading

    if (shouldResetLoading) {
      const timer = setTimeout(() => {
        setLoadingState(false)
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [isLoading])

  // Make sure pagination is displayed when totalPages is greater than 1
  if (pagination.totalPages <= 1) {
    return null
  }

  // Ensure page is within valid range
  const currentPage = Math.max(0, Math.min(state.page || 0, pagination.totalPages - 1))

  return (
    <div className='row'>
      {/* Page Size Selector */}
      {pagination.totalPages > 0 && (
        <div className='row-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
          <div className='col-3'>
            <label className='fs-6' style={{
              fontWeight: "600"
            }}>Rows Per Page : </label>
          </div>
          <div className='col-2'>
            <select
              value={state.size}
              onChange={handlePerPage}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Pagination */}
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div className='table-pagination mt-4'>
          {pagination.totalPages > 0 && (
            <ReactPaginate
              nextLabel={'Next'}
              breakLabel={'...'}
              previousLabel={'Previous'}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              activeClassName={'active'}
              breakClassName={'break-me'}
              forcePage={currentPage}
              containerClassName={'pagination'}
              onPageChange={handlePagination}
              pageCount={pagination.totalPages}
              initialPage={0}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export { UsersListPagination }