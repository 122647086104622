/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon?: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  img?: string
  index?: number
  data?: any
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon = '',
  iconColor,
  title,
  titleColor,
  img,
  description,
  descriptionColor,
  index,
  data,
}) => {
  return (
    <a href='#' className={`card  hoverable ${className}`} style={{backgroundColor: color || ''}}>
      <div className='card-body'>
        {(!img && <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />) ||
          null}
        {(img && (
          <img
            width={35}
            height={35}
            src={`/media/svg/dashboard/${img}.png`}
            alt='images'
            className={`text-${iconColor} fs-3x ms-n1`}
          />
        )) ||
          null}

        <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>{title}</div>
        {/*  <div
          className={`${
            (index && ([1, 2, 5, 6].includes(index) ? 'text-black' : 'text-white')) || 'text-white'
          } fw-bold fs-2 mb-2 mt-5`}
        >
          {title}
        </div> */}

        <div className={`fw-semibold text-white`}>{description}</div>
        {/*   <div
          className={`fw-semibold ${
            (index && ([1, 2, 5, 6].includes(index) ? 'text-black' : 'text-white')) || 'text-white'
          }`}
        >
          {description}
        </div> */}
      </div>
    </a>
  )
}

export {StatisticsWidget5}
