import {Column} from 'react-table'
import {columnGenerators} from '../../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Category Name',
    value: 'categoryName',
  },
  {
    label: 'Item Name',
    value: 'itemName',
  },
  {
    label: 'Logo',
    value: 'avatar',
  },
  {label: 'quantity', value: 'quantity'},
  {label: 'unit Type', value: 'unitType'},
  // {
  //   action: ['edit', 'delete'],
  // },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
