import {Navigate} from 'react-router-dom'
import {useAuth} from '../modules/auth'

const ProtectedSpecificRoute = ({children}) => {
  const {auth} = useAuth()

  if (auth?.data?.userType !== 'SPONSOR') {
    return children
  }
  return <Navigate to='/apps/my-commitments' replace />
}

export default ProtectedSpecificRoute

// userId
