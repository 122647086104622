import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {getAuth} from '../../../../auth'

const API_URL = process.env.REACT_APP_BASE_API_URL
const API_URL_WITH_ADMIN = process.env.REACT_APP_BASE_API_URL_WITH_ADMIN

const USER_URL = `${API_URL}`
const GET_USERS_URL = `${API_URL}`

const instance = axios.create({
  baseURL: 'https://api.example.com', // Your API base URL
  headers: {
    Authorization: 'Bearer YourAccessToken',
  },
})

const getUsers = (query: string, initialApi: string = 'users'): Promise<UsersQueryResponse> => {
  const base = initialApi.includes('http') ? initialApi : API_URL + initialApi
  const baseUrl = `${base}${base.includes('?') ? '&' : '?'}${query}`
  const auth = getAuth()
  return (baseUrl.includes('campaigns/all') ? instance : axios)
    .get(baseUrl, {
      headers: {Authorization: `Bearer ${auth?.data?.token}`},
    })
    .then((d: AxiosResponse<any>) => {
      return d?.data?.data?.details
        ? {...d?.data?.data, data: d?.data?.data?.details}
        : d?.data?.data
        ? d.data
        : d?.data?.content
        ? {data: d.data?.content}
        : d
    })
}

const getUserById = (id?: any, api: any = ''): Promise<User | undefined> => {
  return axios
    .get(`${GET_USERS_URL + api || USER_URL}${id || ''}`)
    .then((response: AxiosResponse<Response<User>>) =>
      response?.data?.data ? response.data : response
    )
    .then((response: Response<User>) => (response?.data?.data ? response.data : response.data))
}

const createUser = (user: any, url?: any): Promise<User | undefined> => {
  const baseURL = url?.includes('http') ? url : url ? USER_URL + url : USER_URL
  return axios
    .post(baseURL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: any, url?: any): Promise<User | undefined> => {
  const baseURL = url?.includes('http') ? url : url ? USER_URL + url : USER_URL
  return axios
    .put(baseURL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (query: any): any => {
  return axios
    .patch(`${USER_URL}${query}`)
    .then((response: any) => response.data)
    .catch((error) => {
      throw error // You can handle errors in your mutation's onError callback
    })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
